<template>
  <div class="view-media pb-5">
    <action-dispatcher action="contents/get" :parameters="{ id }" loading-class="p-5 m-5">
      <action-dispatcher v-if="content.season_tmp" action="seasons/get" :parameters="content.season_tmp.id" loading-class="p-5 m-5">
        <page-header :image="image">
          <h5 v-if="season">Série: {{ season.name }}</h5>
          <h6>
            <span v-if="content.season" class="mr-2">{{ seasonsNames[selectedSeason] }}</span>
            <span>(Episódio {{ content.episode }})</span>
          </h6>
          <h1>{{ content.title }}</h1>
          <p class="metadata d-flex">
            <episode-dropdown v-if="season" class="mr-2" :content="content" :episodes="sortedEpisodes" />
            <span class="card">{{ content.year }}</span>
            <span class="card">{{ duration }}</span>
          </p>
          <p v-html="content.description" />
          <p>{{ tags }}</p>
          <p>
            <span>Categoria: <strong>{{ category }}</strong></span>
          </p>

          <div class="header-buttons d-flex mb-3">
            <watch-button class="mr-2" :content="content" />
            <div class="mr-2">
              <big-list-button :content="content" />
            </div>
          </div>
        </page-header>

        <div class="container mb-5" v-if="files.length">
          <h5 class="mb-4">Material para Download</h5>

          <div class="d-flex flex-wrap btn-download-wrapper">
            <a
              v-for="file in files"
              :key="file.path"
              :href="file.path"
              target="_blank"
              class="btn btn-dark btn-download"
            >
              <i class="icon-download"></i> {{ file.name }} ({{ file.type }})
            </a>
          </div>
        </div>

        <div class="container" v-if="season">
          <b-dropdown class="float-right" :text="seasonsNames[selectedSeason]">
            <b-dropdown-item
              v-for="(s, index) in season.count_season"
              :key="index"
              @click="selectedSeason = index + 1"
            >
              <span class="font-weight-bold">{{ seasonsNames[index + 1] }}</span>
            </b-dropdown-item>
          </b-dropdown>
          <h5 class="mb-4">Próximos episódios ({{ filteredEpisodes.length }})</h5>
          <template v-for="(episode, index) in filteredEpisodes">
            <episode :content="episode" :key="index" />
          </template>
        </div>
      </action-dispatcher>
    </action-dispatcher>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import EpisodeDropdown from '@/components/EpisodeDropdown'
import Episode from '@/components/content/Episode'
import { getDuration, sortSeason } from '@/functions/helpers'
import { mapState } from 'vuex'

import get from 'lodash/get'

export default {
  components: {
    PageHeader,
    EpisodeDropdown,
    Episode
  },
  data () {
    return {
      id: this.$route.params.id,
      selectedSeason: 1
    }
  },
  computed: {
    ...mapState('seasons', ['seasons', 'season']),
    ...mapState('contents', ['content']),
    image () {
      return get(get(this.content, 'thumbnails', []).find(item => item.device === 'web'), 'file', '')
    },
    files () {
      return get(this.content, 'files', [])
    },
    category () {
      return get(this.content, 'categories[0].name', '')
    },
    tags () {
      return get(this.content, 'tags', []).map(item => `#${item.name}`).join(' ')
    },
    duration () {
      return getDuration(
        get(this.content, 'video.duration', 0)
      )
    },
    filteredEpisodes () {
      return get(this.season, 'content', []).filter(episode => episode.season.number === this.selectedSeason)
    },
    sortedEpisodes () {
      return sortSeason(get(this.season, 'content', []))
    },
    seasonsNames () {
      const result = get(this.season, 'content', []).reduce((map, content) => {
        map[content.season.number] = content.season.name
        return map
      }, {})
      console.log(result)
      return result
    }
  },
  watch: {
    content (content) {
      this.selectedSeason = content.season.number
    }
  }
}
</script>

<style lang="scss" scoped>
.view-media {
  .header-buttons {
    ::v-deep .like-button {
      font-size: 28px !important;
    }

    ::v-deep .play-button {
      border: none;
      border-radius: 8px;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      padding: 10px 20px;
      display: flex;
      align-items: center;

      i {
        font-size: 20px;
      }

      &:hover {
        background: rgba(84, 89, 101, 0.8);
      }
    }
  }

  .metadata {
    .card {
      background: transparent;
      border: 1px solid rgba(84, 89, 101, 0.6);
      border-radius: 8px;
      padding: 10px;
      font-weight: bold;
      font-size: 14px;
      margin-right: 8px;

      @media screen and (max-width: 767px) {
        border-radius: 8px;
        padding: 8px;
        font-weight: bold;
        font-size: 12px;
        margin-right: 8px;
      }
    }
  }

  ::v-deep .dropdown-toggle {
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 28px;
    padding-left: 12px;
    background: rgba(67, 69, 74, 0.6);
    border-radius: 8px;
    border: none;
    backdrop-filter: blur(16px);

    &::after {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      right: 4px;
      font-family: 'icomoon' !important;
      content: "\e919";
      border: none !important;
      font-size: 24px;
    }
  }

  ::v-deep .dropdown-menu.show {
    background: rgba(67, 69, 74, 0.6);
    backdrop-filter: blur(16px);
    max-width: 240px;

    .dropdown-item {
      text-shadow: none;
      color: white;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      padding: 8px 12px;
      white-space: normal;

      &:hover {
        background: rgba(255, 255, 255, 0.08);
      }
    }
  }

  .btn-download-wrapper {
    margin: -4px;

    .btn-download {
      margin: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 22px;
        line-height: 22px;
        margin-right: 4px;
      }
    }
  }
}
</style>

<template>
  <button class="content-episode card mb-3" @click="watch">
    <div class="row no-gutters">
      <div class="col-md-4">
        <img :src="image" class="card-img img-fluid">
      </div>
      <div class="col-md-8 d-flex">
        <div class="card-body d-flex flex-column">
          <div class="d-flex justify-content-between">
            <h5 class="card-title">
              Episódio {{ content.episode }}: {{ content.title }}
            </h5>
            <div class="icons">
              <template v-if="isWatched">
                <i class="icon-check"></i> <small>Assistido</small>
              </template>
            </div>
          </div>
          <p class="card-text" v-html="content.description" />
          <div class="d-flex justify-content-between pt-4 flex-fill align-items-end">
            <div class="flex-fill"></div>
            <h6>{{ duration }}</h6>
          </div>
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import { getDuration } from '@/functions/helpers'
import get from 'lodash/get'

export default {
  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    image () {
      return get(get(this.content, 'thumbnails', []).find(item => item.device === 'web'), 'small', '')
    },
    duration () {
      return getDuration(
        get(this.content, 'video.duration', 0)
      )
    },
    isWatched () {
      return this.content.continue_watch.finished ||
        this.content.continue_watch.duration / (this.content.video.duration + 1) >= 0.9
    }
  },
  methods: {
    watch () {
      this.$router.push({
        name: 'content',
        params: {
          id: this.content.id,
          slug: this.content.slug
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content-episode {
  width: 100%;
  padding: 0;
  background: rgba(84, 89, 101, 0.2);
  color: white;
  text-align: left;
  transition: 0.2s background-color;

  .row {
    width: 100%;
  }

  &:hover {
    background: rgba(84, 89, 101, 0.1);
  }

  .card-body {
    padding: 12px 16px;
  }

  .icons {
    display: flex;
    justify-content: center;
    align-items: center;

    ::v-deep i {
      font-size: 24px;
    }
  }

  h6 {
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    h5 {
      font-size: 18px;
    }

    h6 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }
}
</style>

<template>
  <div v-if="content.season" class="episode-dropdown d-flex flex-column">
    <b-dropdown class="flex-fill" :text="text">
      <b-dropdown-item
        v-for="(episode, index) in episodes"
        :key="index"
        @click="goTo(episode)"
      >
        <span class="font-weight-bold">T{{ episode.season.number }}E{{ episode.episode }}</span>
        : {{ episode.title }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => ({})
    },
    episodes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    text () {
      return `T${this.content.season.number}E${this.content.episode}: ${this.content.title}`
    }
  },
  methods: {
    goTo (episode) {
      this.$router.push({
        name: 'content',
        params: {
          id: episode.id,
          slug: episode.slug
        }
      })
    },
    loadEpisodes () {
      if (!this.season) { return }
      this.loadingEpisodes = true
      this.$store
        .dispatch('contents/getSeason', { id: this.season.id })
        .finally(() => {
          this.loadingEpisodes = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.episode-dropdown {
  position: relative;
  max-width: 60%;
}
</style>
